:root {
   --sidebar-width: 217px;
   --footer-height: 128px;
   --header-height: 56px;
}

* {
   margin: 0;
   padding: 0;
}

body {
   font-family: "Roboto", sans-serif;
}

a {
   color: inherit;
   text-decoration: none;
}

.main {
   width: calc(100% - var(--sidebar-width));
   margin-left: var(--sidebar-width);
   background-color: #f2f2f5;
}

.content {
   padding: 20px 30px 40px;
}

.not-found {
   height: calc(100vh - var(--footer-height) - 20px - 40px);
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}

.loader {
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
}

.primary-link {
   color: #0288d1;
}

.MuiTypography-paragraph {
   color: #8a898c;
}

/* Responsive */
@media only screen and (max-width: 900px) {
   .main {
      margin-left: 0;
      width: 100%;
   }

   .content {
      padding: 20px 16px 40px;
   }

   .not-found {
      height: calc(100vh - var(--footer-height) - 20px - 40px - var(--header-height));
   }

   .loader {
      height: calc(100vh - var(--header-height));
   }
}

@media only screen and (max-width: 300px) {
   .content {
      padding: 20px 8px 40px;
   }
}
